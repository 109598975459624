import React from 'react';

const Counter = ({
  setIsCounting,
  counter,
  selectedNumber,
  resetCount,
  setPause,
  pause,
}) => {
  const calculateStrokeDashoffset = (counter, selectedNumber) => {
    const radius = 45;
    const circumference = 2 * Math.PI * radius;
    const progress = (counter / selectedNumber) * 100;
    return circumference - (progress / 100) * circumference;
  };

  const handlePause = () => {
    setPause(!pause);
  };

  const handleReset = () => {
    setIsCounting(false);
    setPause(false);
  };

  return (
    <div className="counters">
      <h1>{resetCount}</h1>
      <div className="circle-progress">
        <svg width="120" height="120">
          <circle
            cx="60"
            cy="60"
            r="45"
            stroke="#ddd"
            strokeWidth="15"
            fill="none"
          />
          <circle
            cx="60"
            cy="60"
            r="45"
            stroke="#4CAF50"
            strokeWidth="15"
            fill="none"
            strokeDasharray="282.6"
            strokeDashoffset={calculateStrokeDashoffset(
              counter,
              selectedNumber
            )}
            transform="rotate(-90 60 60)"
          />
          <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="2em">
            {counter}
          </text>
        </svg>
      </div>
      <button className="continue_button" onClick={handlePause}>
        {pause ? 'Continue' : 'Pause'}
      </button>
      <br />
      <button className="regular_button" onClick={handleReset}>
        Reset
      </button>
    </div>
  );
};

export default Counter;
