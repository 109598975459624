import React, { useState, useEffect } from 'react';
import './App.css';
import Config from './components/Config';
import Counter from './components/Counter';
import CountdownTimer from './components/CountdownTimer';

const App = () => {
  const [selectedNumber, setSelectedNumber] = useState(
    localStorage.getItem('selectedNumber') || ''
  );
  const [counter, setCounter] = useState(0);
  const [resetCount, setResetCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [pause, setPause] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [countdownStarted, setCountdownStarted] = useState(false);

  useEffect(() => {
    if (selectedNumber) {
      localStorage.setItem('selectedNumber', selectedNumber);
    }
  }, [selectedNumber]);

  const handleStart = () => {
    if (selectedNumber) {
      setCounter(0);
      setResetCount(0);
      setIsCounting(false);
      setCountdownStarted(true);
      setShowCounter(false);
    }
  };

  const handleContinue = () => {
    setIsCounting(false);
    setCountdownStarted(true);
  };

  const onTimerEnd = () => {
    setShowCounter(true);
    setCountdownStarted(false);
    if (isStarting) {
      setCounter(0);
      setResetCount(0);
      setIsCounting(true);
    } else {
      setIsCounting(true);
    }
  };

  useEffect(() => {
    let interval;
    if (isCounting && !pause) {
      interval = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter + 1 > selectedNumber) {
            setResetCount((prevResetCount) => prevResetCount + 1);
            return 0;
          }
          return prevCounter + 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCounting, selectedNumber, pause]);

  return (
    <div className="container">
      {!isCounting && !countdownStarted ? (
        <Config
          setIsStarting={setIsStarting}
          setSelectedNumber={setSelectedNumber}
          selectedNumber={selectedNumber}
          setShowCounter={setShowCounter}
          handleStart={handleStart}
          handleContinue={handleContinue}
        />
      ) : countdownStarted ? (
        <CountdownTimer seconds={10} onTimerEnd={onTimerEnd} />
      ) : showCounter ? (
        <Counter
          setIsCounting={setIsCounting}
          setCounter={setCounter}
          setResetCount={setResetCount}
          counter={counter}
          selectedNumber={selectedNumber}
          resetCount={resetCount}
          setPause={setPause}
          pause={pause}
        />
      ) : null}
    </div>
  );
};

export default App;
