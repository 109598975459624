import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ seconds, onTimerEnd }) => {
  const [counter, setCounter] = useState(seconds);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    if (counter === 0) {
      setTimeout(onTimerEnd, 1000);
    }
  }, [counter, onTimerEnd]);

  return (
    <div className="counters">
      <h1>{counter === 0 ? 'GO!💪🏻' : counter}</h1>
    </div>
  );
};

export default CountdownTimer;
