import React from 'react';

const Config = ({
  setIsStarting,
  setSelectedNumber,
  selectedNumber,
  handleStart,
  handleContinue,
}) => {
  return (
    <div className="input-container">
      <h2>
        Enter a number between <br /> 1 to 20:
      </h2>
      <input
        type="number"
        min="1"
        max="20"
        value={selectedNumber}
        onChange={(e) => {
          const value = Number(e.target.value);
          if (value >= 1 && value <= 20) {
            setSelectedNumber(value);
          } else {
            setSelectedNumber('');
          }
        }}
      />
      <button
        className="start_button"
        onClick={() => {
          setIsStarting(true);
          handleStart();
        }}
      >
        Start
      </button>
      <br />
      <button
        className="continue_button_big"
        onClick={() => {
          setIsStarting(false);
          handleContinue();
        }}
      >
        Continue
      </button>
    </div>
  );
};

export default Config;
